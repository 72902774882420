import React from "react";
import { observer } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import { StoreContext } from "../stores/context";
import {Breakpoints} from "../config";
import classNames from "classnames";
import {Settings} from "../config.js"

const styles = (theme) => ({
  footer: {
    margin: "0",
    padding: "0 20px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",

    [theme.breakpoints.down(Breakpoints.md)]: {
      padding: "10px 0 24px 0",
      flexDirection: "column",
      alignItems: "center",
      "& > *": {
        marginBottom:"24px!important",
      }
    },
  },
  icons: {
    maxHeight: "70px",

    maxWidth: "70%",
    width:"auto",
    [theme.breakpoints.down(Breakpoints.md)]: {
      maxWidth: "100%",
    },
  },
  copy: {
    fontSize: "12px",
    color: "#57728e",
    letterSpacing: "1.3pt",
    margin: "0 0.5rem",
  },
});

class Footer extends React.Component {
  static contextType = StoreContext;

  render() {
    const { classes } = this.props;
    const { langStore } = this.context;

    return (
      <div className={classes.footer}>
        <img
          src={`/images/logo-footer-${langStore.langKey}.png`}
          src={
            Settings.Tenant.IsUlead
                ? `/images/logo-footer-${langStore.langKey}.png`
                : "/images/3E4U footer.jpg"
          }
          alt="Logo"
          className={classes.icons}
        />
        <div className={classes.copy}>&copy;U-LEAD with Europe</div>
        <img
          src="/images/GIZ_logo.svg"
          alt="GIZ Logo"
        />
      </div>
    );
  }
}

export default withStyles(styles)(observer(Footer));
