import React from "react";
import {observer} from "mobx-react";
import {Link, NavLink, withRouter} from "react-router-dom";
import {AppBar, Typography, MenuItem, Select} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import classNames from "classnames";
import {StoreContext} from "../stores/context";
import {forumRoute} from "../utils/routes";
import fetchData from "../utils/fetch";
import {I18n} from "../i18n";

import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import DraftsIcon from '@material-ui/icons/Drafts';
import {Settings} from "../config";

const styles = (theme) => ({
    root: {
        "& h6":
            {
                fontFamily: "Montserrat!important",
            }
    },

    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
        "& svg":
            {
                width: "36px",
                height: "36px",
                color: "#E6FEFF",
            }
    },

    drawerHead:
        {
            height: "72px",
            display: "flex"
        },
    drawerLogo:
        {
            marginTop: "25px",
            marginLeft: "30px",
            flexGrow: "1",
            "& img":
                {
                    width: "90px"
                }
        },
    drawerClose:
        {
            marginTop: "13px",
            marginRight: "18px",
            "& svg":
                {
                    color: "#AFDAE8",
                }
        },

    appBar: {
        display: "flex",
        height: "80px",
        backgroundColor: "#4D64A9",
        "& nav": {
            display: "flex",
            justifyContent: "space-around",
            minWidth: "860px",
            width: "100%",
            padding: "7px 0 0 ",
            marginLeft: "43px",

        },
        "& .icon": {
            display: "block",
            height: "25px",
            width: "25px",
        },
        "& .icon-user": {
            background: "url(/images/icons-marin/header-user.svg) no-repeat 0 0",
            width: "17px",
            marginLeft: "14px",
            marginRight: "0",
        },

    },
    toolBar:
        {
            height: "80px",
            display: "flex",
        },

    headerLogo:
        {
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            "& img":
                {
                    paddingTop: "4px",
                    height: "36px"
                },

        },
    appMenu: {
        "&.menu-popup": {
            position: "absolute",
            width: "225px",
            backgroundColor: "#4D64A9",
            paddingTop: "40px",
            borderRadius: "5px",
            paddingBottom: "20px",
            display: "none",
        },
        "&.menu-popup_dashboard": {
            width: "175px",
        },
        "& .icon": {
            display: "block",
            height: "14px",
            width: "26px",
            color: "#E6FEFF",
            fontSize: "16px",
            margin: " 5px 0 0 7px",
            position: "absolute",
            left: "5px",
            top: "0",
        },

        "& > div:last-child": {
            backgroundColor: "#4D64A9",
            border: "none",
            boxShadow: "none",
            marginTop: "80px",
        },
        "& p": {
            outline: "none",
        },
        "& a": {
            color: "#AFDAE8",
            fontWeight: "700",
            fontSize: "13px",
            padding: "5px 10px 0",
            paddingLeft: "40px",
            position: "relative",
            letterSpacing: "0.1em",
        },
        "&.user-menu": {
            paddingBottom: "5px",
            width: "130px",
            right: "0",
            background:
                "linear-gradient(to top, #4D64A9, #4D64A9 155px, transparent 130px)",


            "& a": {
                padding: "10px 10px 0 40px",
                "&:last-child": {
                    marginBottom: "15px",
                },
            },
            "& .icon": {
                height: "100%",
                position: "absolute",
                left: "5px",
            },
        },
        "&.register-menu": {
            width: "153px",
            "& a": {
                padding: "5px 10px 0 40px",
                "&.link-hromadas": {
                    alignItems: "flex-start",
                    textAlign: "left",
                    "& i": {
                        width: "30px",
                        marginRight: "7px",
                    },
                },
            },
            "& .icon": {
                height: "100%",
                marginLeft: "4px",
            },
        },
    },

    logo: {
        marginTop: "10px",
        width: "120px",
        height: "32px",
    },
    appBarLink: {


        color: "#AFDAE8",
        textDecoration: "none",
        fontFamily: "Montserrat!important",
        fontStyle: "normal",
        fontSize: "15.12px",
        fontWeight: 400,
        "& a": {
            color: "#AFDAE8",
        },

        "&:hover, &.active": {
            "& .menu-popup": {
                display: "block",
            },
        },

        "&:hover, &.active, &.active-link": {
            color: "#E6FEFF",
            "& .icon-home": {
                background: "url(/images/icons/header-home.svg) no-repeat 0 0",
            },
            "& .icon-register": {
                background: "url(/images/icons/header-register.svg) no-repeat 0 0",
            },
            "& .icon-indicators": {
                background: "url(/images/icons/header-indicators.svg) no-repeat 0 0",
            },
            "& .icon-dashboard": {
                background: "url(/images/icons/header-dashboard.svg) no-repeat 0 0",
            },
            "& .icon-search": {
                background: "url(/images/icons/header-search.svg) no-repeat 0 0",
            },
            "& .icon-templates": {
                background: "url(/images/icons/header-templates.svg) no-repeat 0 0",
            },
            "& .icon-administration": {
                background:
                    "url(/images/icons/header-administration.svg) no-repeat 0 0",
            },
            "& .icon-support": {
                background: "url(/images/icons/header-support.svg) no-repeat 0 0",
            },
            "& a": {
                color: "#E6FEFF",
            },
        },
        "& svg": {
            height: "100%",
            width: "100%",
            maxHeight: "35px",
            maxWidth: "35px",
        },
        "& i": {
            marginRight: "10px",
            [theme.breakpoints.down(1440)]: {
                marginRight: "0",
            },
        },
        "&.active": {
            color: "##E6FEFF",
            fontWeight: 900,
        },
        "&.user-link": {
            display: "flex",
            flexFlow: "row",
            color: "#AFDAE8",
            "&:hover": {
                color: "#E6FEFF"
            },
            "&:hover .icon-user": {
                background: "url(/images/icons-marin/header-user-selected.svg) no-repeat 0 0",
            },

            //marginRight: "24px",
            "& .icon": {
                top: "5px",
            },
        },
        [theme.breakpoints.down(1440)]: {
            flexDirection: "column",
        },
    },
    appBarMenuLink: {
        fontFamily: "Montserrat!important",
        fontWeight: "400!important",
        fontStyle: "normal!important",
        fontSize: "14px",
        color: "#AFDAE8",
        textDecoration: "none",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
        padding: "5px 10px 0",
        outline: "none",
        minWidth: "150px",
        backgroundColor: "transparent !important",
        textAlign: "left",
        "&:hover, &.active": {
            color: "##E6FEFF",
            opacity: "1",
            "& .icon-users": {
                background:
                    "url(/images/icons/header-users.svg) no-repeat center center / 20px 20px",
            },
            "& .icon-graduation-cap": {
                background:
                    "url(/images/icons/header-graduation-cap.svg) no-repeat center center / 20px 20px",
            },
            "& .icon-microphone": {
                background:
                    "url(/images/icons/header-microphone.svg) no-repeat center center / 20px 20px",
            },
            "& .icon-hromadas": {
                background:
                    "url(/images/icons/header-hromadas.svg) no-repeat center center / 26px 26px",
            },
            "& .icon-item": {
                background:
                    "url(/images/icons/header-item.png) no-repeat center center / 12px 12px",
            },
            "& .icon-settings": {
                background:
                    "url(/images/icons/header-settings.svg) no-repeat center center / 17px 17px",
            },
            "& .icon-password": {
                background:
                    "url(/images/icons/header-password.svg) no-repeat center center / 15px 15px",
            },
            "& .icon-signout": {
                background:
                    "url(/images/icons/header-signout.svg) no-repeat center center / 15px 15px",
            },
            "& .icon-calendar": {
                background:
                    "url(/images/icons/header-calendar.svg) no-repeat center center / 17px 17px",
            },
            "& .icon-practice": {
                background:
                    "url(/images/icons/header-practice.svg) no-repeat center center/ 17px 17px",
            },
        },

    },
    langSelector: {

        "&> div": {
            background: "none!important",
        },
        "& .select": {
            float: "right",
            left: "none",

        },
        "& .select:before": {
            display: "none",
        },
        "& .select:after": {
            display: "none",
        },
        "& .select > div": {
            padding: "6px 6px 7px",
        },
        "& .select > div > div": {

            padding: "0",
            textAlign: "center",
            backgroundColor: "#191970",

            textTransform: "uppercase",
            fontWeight: "400",
            color: "#B4C3D1",
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "30px",
            width: "30px",
            margin: "0 0 0 14px",
            borderRadius: "4px",
        },
        "& .select > div > div:hover": {
            // backgroundColor: "#AFDAE8",
        },
        "& .select > div > svg": {
            display: "none",
        },
    },

    subMenu:
        {},
    drawerMainLevel: {
        color: "#ADD8E6",
        textDecoration: "none",
        fontFamily: "Montserrat!important",
        fontStyle: "normal",
        fontSize: "14px",
        fontWeight: 500,
    },

    drawerSubLevel: {
        color: "#ADD8E6",
        textDecoration: "none",
        fontFamily: "Montserrat!important",
        fontStyle: "normal",
        fontSize: "14px",
        fontWeight: 500,
    },
    listItem:
        {
            borderTop: "1px solid #ADD8E6",
            padding: "10px 30px",
            " &":
                {
                    color: "#ADD8E6",
                }
        },
    subItem:
        {
            paddingLeft: "60px"
        },
    listItemText:
        {
            paddingLeft: 0,
        },
    drawerPaper: {
        background: "#4D64A9",
        width: "100%",

        "& .icon-users": {
            background:
                "url(/images/icons-marin/header-users.svg) no-repeat top center / 20px 20px",
            top: "-4px",
        },
        "& .icon-graduation-cap": {
            background:
                "url(/images/icons-marin/header-graduation-cap.svg) no-repeat center center / 20px 20px",
        },
        "& .icon-microphone": {
            background:
                "url(/images/icons-marin/header-microphone.svg) no-repeat center center / 20px 20px",
        },
        "& .icon-hromadas": {
            background:
                "url(/images/icons-marin/header-hromadas.svg) no-repeat center center / 26px 26px",
        },
        "& .icon-item": {
            background:
                "url(/images/icons-marin/header-item.png) no-repeat center center / 12px 12px",
        },
        "& .icon-settings": {
            background:
                "url(/images/icons-marin/header-settings.svg) no-repeat center center/ 17px 17px",
        },
        "& .icon-password": {
            background:
                "url(/images/icons-marin/header-password.svg) no-repeat center center / 15px 15px",
        },
        "& .icon-signout": {
            background:
                "url(/images/icons-marin/header-signout.svg) no-repeat center center / 15px 15px",
            top: "1px",
        },
        "& .icon-calendar": {
            background:
                "url(/images/icons-marin/header-calendar.svg) no-repeat center center/ 17px 17px",
            top: "-1px",
        },
        "& .icon-practice": {
            background:
                "url(/images/icons-marin/header-practice.svg) no-repeat center center / 17px 17px",
        },

        "& .icon": {
            display: "block",
            height: "25px",
            width: "25px",
        },
        "& .icon-home": {
            background: "url(/images/icons-marin/header-home.svg) no-repeat 0 0",
        },
        "& .icon-register": {
            background: "url(/images/icons-marin/header-register.svg) no-repeat 0 0",
        },
        "& .icon-indicators": {
            background:
                "url(/images/icons-marin/header-indicators.svg) no-repeat 0 0",
        },
        "& .icon-dashboard": {
            background: "url(/images/icons-marin/header-dashboard.svg) no-repeat 0 0",
        },
        "& .icon-search": {
            background: "url(/images/icons-marin/header-search.svg) no-repeat 0 0",
        },
        "& .icon-templates": {
            background: "url(/images/icons-marin/header-templates.svg) no-repeat 0 0",
        },
        "& .icon-administration": {
            background:
                "url(/images/icons-marin/header-administration.svg) no-repeat 0 0",
        },
        "& .icon-support": {
            background: "url(/images/icons-marin/header-support.svg) no-repeat 0 0",
            height: "28px",
            width: "28px",
            marginBottom: "-3px",
        },
        "& .icon-user": {
            background: "url(/images/icons-marin/header-user.svg) no-repeat 0 0",
            width: "17px",
            marginLeft: "14px",
            marginRight: "0",
            [theme.breakpoints.down(1440)]: {
                marginLeft: "4px",
                "& :hover": {
                    background: "url(/images/icons-marin/header-user-selected.svg) no-repeat 0 0",
                }
            },
        },
    },
});

class SmallHeader extends React.Component {
    static contextType = StoreContext;

    state = {
        showHeader: true,
        menuOpen: false,
        registry: false,
        indicators: false,
        dashboard: false,
        search: false,
        templates: false,
        administration: false,
        support: false,

    };

    componentDidMount() {
        const currentPath = document.location.pathname;
        if (
            currentPath === "/login" ||
            currentPath === "/forgot-password" ||
            currentPath === "/reset-password" //||
            // currentPath === "/search/oes" ||
            // currentPath === "/search/xs"
        ) {
            this.setState({showHeader: false});
        }
    }

    onSignOutClick = async () => {
        const {routerStore, authStore} = this.context;
        await authStore.signout();
        routerStore.goToLogin();
    };

    handleChangeLanguage = (e) => {
        this.context.userStore.updateLanguage(e.target.value);
    };

    handleChangePhase = (new_phase_number) => {
        return async () => {
            await fetchData("put", "/settings", {
                current_phase_number: new_phase_number,
            });

            window.location.reload();
        };
    };

    getRegisterActiveLinkClass = () => {
        const registerLinks = [
            "/events",
            "/participants",
            "/trainers",
            "/facilitators",
            "/good-practices",
            "/communities",
        ];
        return !!registerLinks.find((item) => item === document.location.pathname)
            ? "active-link"
            : "";
    };

    getIndicatorActiveLinkClass = () => {
        const indicatorLinks = ["/indicators", "/indicator/"];
        return !!indicatorLinks.find((item) =>
            document.location.pathname.includes(item)
        )
            ? "active-link"
            : "";
    };

    getDashboardActiveLinkClass = () => {
        const dashboardLinks = [
            "/dashboard",
            "/graphs",
            "/indicator-graphs",
            "/map",
        ];

        return !!dashboardLinks.find((item) =>
            document.location.pathname.includes(item)
        )
            ? "active-link"
            : "";
    };

    getGoodPracticeActiveLinkClass = () => {
        const goodPracticeLink = "/good-practice";
        return !!document.location.pathname.includes(goodPracticeLink)
            ? "active-link"
            : "";
    };

    getAdministrationActiveLinkClass = () => {
        const administrationLinks = [
            "/user",
            "/maillog",
            "/team",
            "/funding-source",
            "/employer-level",
            "/employer-type",
            "/region",
            "/category",
            "/target-group",
            "/organizer",
            "/format",
            "/event-character",
            "/europe-actions",
            "/thematic-packages",
        ];
        return !!administrationLinks.find(
            (item) => item === document.location.pathname
        )
            ? "active-link"
            : "";
    };

    toggleMenu = (open) => () => {
        this.setState({
            menuOpen: open,
        });
    };

    toggleSubMenu = (item) => {
        this.setState(state => ({[item]: !state[item]}));
    };

    navigate = (to) => {
        this.setState({menuOpen: false})
        this.props.history.push(to);
    }

    renderMainMenu = (menu) => {
        return (<List
            component="nav"
            className={this.props.classes.root}
        >
            {menu.filter(x => x.allowed).map(this.renderMainMenuItem)}
        </List>)
    }

    renderMainMenuItem = (item) => {
        return (<><ListItem className={this.props.classes.listItem} button onClick={() => {
            item.sub ? this.toggleSubMenu(item.key) : this.navigate(item.link)
        }}>
            <ListItemIcon>
                <i className={"icon " + item.icon}></i>
            </ListItemIcon>
            <ListItemText inset primary={item.label} classes={{primary: this.props.classes.drawerMainLevel}}
                          className={this.props.classes.listItemText}/>
            {item.sub && (this.state[item.key] ? <ExpandLess/> : <ExpandMore/>)}

        </ListItem>
            {item.sub && (
                <Collapse in={this.state[item.key]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding classes={{root: this.props.classes.subMenu}}>
                        {item.sub.filter(x => x.allowed).map(this.renderSubMenuItem)}
                    </List>
                </Collapse>
            )}</>)
    }

    renderSubMenuItem = (item) => {
        return (<ListItem button className={classNames(this.props.classes.listItem, this.props.classes.subItem)}
                          onClick={() => this.navigate(item.link)}>
            <ListItemIcon>
                <i className={"icon " + item.icon}></i>
            </ListItemIcon>
            <ListItemText inset primary={item.label} classes={{primary: this.props.classes.drawerSubLevel}}
                          className={this.props.classes.listItemText}/>
        </ListItem>)
    }

    render() {
        const {userStore, langStore} = this.context;
        const {login, isMinreg, data: currentUser} = userStore;
        const {lang} = langStore;
        const {showHeader} = this.state;

        if (!currentUser) {
            return "";
        }

        const {classes} = this.props;
        let showAdminMenu =
            currentUser &&
            currentUser.authorities.filter(
                (r) =>
                    [
                        "ROLE_ADMIN",
                        "ROLE_ANALYST",
                        "ROLE_USER",
                        "ROLE_RO",
                        "ROLE_HUB",
                    ].indexOf(r) >= 0
            ).length > 0;
        const isOes =
            currentUser && currentUser.authorities.indexOf("ROLE_OES") >= 0;
        const isGuest =
            currentUser && currentUser.authorities.indexOf("ROLE_GUEST") >= 0;
        const showTrainers = currentUser && !isOes && !isGuest;
        const showFacilitators = currentUser && !isOes && !isGuest;
        const dashboardAllowed = !isMinreg;
        const anotherPhaseNumber = currentUser.currentPhaseNumber == 1 ? 2 : 1;
        const categoryTitle =
            currentUser && currentUser.categoryCaptions[lang.toLowerCase()];

        if (isMinreg && document.location.pathname == "/") {
            showHeader = false;
        }

        let menu = [
            {
                key: "home", icon: "icon-home", label: I18n[lang].header.home, link: "/", allowed: true,
            },
            {
                key: "registries", icon: "icon-register", label: I18n[lang].header.registries, link: "", allowed: true,
                sub: [
                    {icon: "icon-calendar", label: I18n[lang].header.events, link: "/events", allowed: true},
                    {
                        icon: "icon-users",
                        label: I18n[lang].header.participants,
                        link: "/participants",
                        allowed: !isMinreg
                    },
                    {
                        icon: "icon-graduation-cap",
                        label: I18n[lang].header.trainers,
                        link: "/trainers",
                        allowed: showTrainers
                    },
                    {
                        icon: "icon-microphone",
                        label: I18n[lang].header.facilitators,
                        link: "/facilitators",
                        allowed: showFacilitators
                    },
                    {icon: "icon-practice", label: I18n[lang].header.practice, link: "/good-practices", allowed: true},
                    {icon: "icon-hromadas", label: I18n[lang].header.communities, link: "/communities", allowed: true}]
            },
            {
                key: "indicators",
                icon: "icon-indicators",
                label: I18n[lang].header.indicators,
                link: "/indicators",
                allowed: !isMinreg
            },
            // {
            //     key: "dashboard",
            //     icon: "icon-dashboard",
            //     label: I18n[lang].header.dashboard,
            //     link: "",
            //     allowed: !isMinreg && dashboardAllowed,
            //     sub: [
            //         {icon: "icon-item", label: I18n[lang].header.graphs, link: "/graphs", allowed: true},
            //         {icon: "icon-item", label: I18n[lang].header.map, link: "/map", allowed: !isMinreg},
            //     ]
            // },
            {
                key: "oes",
                icon: "icon-search",
                label: I18n[lang].header.searchAnalyse,
                link: "/search/oes",
                allowed: true
            },
            // {
            //     key: "templates",
            //     icon: "icon-templates",
            //     label: I18n[lang].header.templates,
            //     link: "/templates",
            //     allowed: !isMinreg && !isGuest
            // },
            // {
            //     key: "admin",
            //     icon: "icon-administration",
            //     label: I18n[lang].header.administration,
            //     link: "",
            //     allowed: showAdminMenu,
            //     sub: [
            //         {icon: "icon-item", label: I18n[lang].header.userManagement, link: "/user", allowed: true},
            //         {icon: "icon-item", label: I18n[lang].header.mailLog, link: "/maillog", allowed: true},
            //         {icon: "icon-item", label: I18n[lang].header.team, link: "/team", allowed: true},
            //         {icon: "icon-item", label: I18n[lang].header.fundingSource, link: "/funding-source", allowed: true},
            //         {icon: "icon-item", label: I18n[lang].header.employerLevel, link: "/employer-level", allowed: true},
            //         {icon: "icon-item", label: I18n[lang].header.employerType, link: "/employer-type", allowed: true},
            //         {icon: "icon-item", label: I18n[lang].header.oblast, link: "/region", allowed: true},
            //         {
            //             icon: "icon-item",
            //             label: this.categoryTitle || I18n[lang].header.category,
            //             link: "/category",
            //             allowed: true
            //         },
            //         {icon: "icon-item", label: I18n[lang].header.targetGroup, link: "/target-group", allowed: true},
            //         {icon: "icon-item", label: I18n[lang].header.targetLevel, link: "/target-level", allowed: true},
            //         {icon: "icon-item", label: I18n[lang].header.organizer, link: "/organizer", allowed: true},
            //         {icon: "icon-item", label: I18n[lang].header.eventFormat, link: "/format", allowed: true},
            //         {
            //             icon: "icon-item",
            //             label: I18n[lang].header.eventCharacter,
            //             link: "/event-character",
            //             allowed: true
            //         },
            //         {
            //             icon: "icon-item",
            //             label: I18n[lang].header.regionalInitiatives,
            //             link: "/europe-actions",
            //             allowed: true
            //         },
            //         {
            //             icon: "icon-item",
            //             label: I18n[lang].header.thematicSupportPackages,
            //             link: "/thematic-packages",
            //             allowed: true
            //         },
            //         {icon: "icon-item", label: I18n[lang].header.csoGrants, link: "/cso-grants", allowed: true},
            //         {icon: "icon-item", label: I18n[lang].header.donorProjects, link: "/donor-project", allowed: true}]
            // },
        ];


        return (
            <div className={classes.root}>
                <Typography component="div">
                    {showHeader && (
                        <>
                            <SwipeableDrawer
                                open={this.state.menuOpen}
                                onClose={this.toggleMenu(false)}
                                onOpen={this.toggleMenu(true)}
                                classes={{paper: classes.drawerPaper}}

                            >
                                <div
                                    tabIndex={0}
                                    role="button"

                                >
                                    <div className={classes.drawerHead}>

                                        <div className={classes.drawerLogo}><Link onClick={() => {
                                            this.navigate("/")
                                        }}><img
                                            src={
                                                Settings.Tenant.IsUlead
                                                    ? `/images/U-LEAD_Logo_yellow_light_${lang}.png`
                                                    : "/images/3E4u_logo.png"
                                            }
                                            alt="Logo"

                                        /></Link></div>

                                        <div className={classes.drawerClose}><IconButton
                                            onClick={this.toggleMenu(false)}>
                                            <CloseIcon/>
                                        </IconButton></div>
                                    </div>
                                    <div className={classes.fullList}>
                                        {this.renderMainMenu(menu)}
                                    </div>

                                </div>
                            </SwipeableDrawer>

                            <AppBar className={classes.appBar}>
                                <Toolbar className={classes.toolBar}>
                                    <IconButton className={classes.menuButton} color="inherit" aria-label="Menu"
                                                onClick={this.toggleMenu(true)}>
                                        <MenuIcon/>
                                    </IconButton>

                                    <div className={classes.headerLogo}><Link to="/"><img
                                        src={
                                            Settings.Tenant.IsUlead
                                                ? `/images/U-LEAD_Logo_yellow_light_${lang}.png`
                                                : "/images/3E4U logo.png"
                                        }
                                        alt="Logo"

                                    /> </Link></div>


                                    <div className={classes.langSelector}>
                                        <Select
                                            className="select"
                                            value={lang}
                                            renderValue={(value) => {
                                                return value === "Ukr" ? "UA" : "ENG";
                                            }}
                                            onChange={this.handleChangeLanguage}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        backgroundColor: "#4D64A9",
                                                        border: "none",
                                                        boxShadow: "none",
                                                        marginTop: "-24px",
                                                        right: "0",

                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value="Eng" className={classes.appBarMenuLink}>
                                                English
                                            </MenuItem>
                                            <MenuItem value="Ukr" className={classes.appBarMenuLink}>
                                                Українська
                                            </MenuItem>
                                        </Select>
                                    </div>
                                    {currentUser && (
                                        <div className={classNames(classes.appBarLink, "user-link")}>

                                            <i className="icon icon-user"></i>
                                            <div
                                                className={classNames(
                                                    classes.appMenu,
                                                    "menu-popup",
                                                    "user-menu"
                                                )}
                                            >
                                                <Typography>
                                                    <Link
                                                        onClick={this.handleChangePhase(anotherPhaseNumber)}
                                                        className={classes.appBarMenuLink}
                                                        to="#"
                                                    >
                                                        <i className="icon icon-settings"></i>
                                                        Switch to Phase {anotherPhaseNumber}
                                                    </Link>

                                                    <Link className={classes.appBarMenuLink} to="/settings">
                                                        <i className="icon icon-settings"></i>
                                                        Settings
                                                    </Link>
                                                    {!isGuest && (
                                                        <Link className={classes.appBarMenuLink} to="/password">
                                                            <i className="icon icon-password"></i>
                                                            Password
                                                        </Link>
                                                    )}
                                                    <Link
                                                        onClick={this.onSignOutClick}
                                                        className={classes.appBarMenuLink}
                                                        to="#"
                                                    >
                                                        <i className="icon icon-signout"></i>
                                                        Sign out
                                                    </Link>
                                                </Typography>
                                            </div>
                                        </div>
                                    )}


                                </Toolbar>

                            </AppBar>
                        </>)}
                </Typography>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(observer(SmallHeader)));
